<template>
  <div class="p-12 bg-gray-100 max-h-screen w-full overflow-y-auto"> <!-- Élément principal avec un padding plus large -->
    <div class=" gap-8 p-6 flex min-h-dvh pb-12">
      <!-- Section des Listes -->
      <div class="max-w-2xl mx-auto"> <!-- Augmenter la largeur maximale -->
        <div class="mb-8 mt-12">
          <h1 class="text-4xl font-bold mb-6">Liste des Prix</h1> <!-- Titre plus grand -->
        </div>

        <div class="bg-white shadow-lg rounded-lg p-6 max-h-[500px] overflow-y-auto"> <!-- Augmenter la taille et padding de la liste -->
          <ul class="space-y-6">
            <li v-for="item in prixList" :key="item.id" class="flex justify-between items-center p-6 border-b border-gray-200">
              <span class="text-lg font-semibold max-w-[270px] flex w-full">{{ item.name }}</span>
              <div class="space-x-3">
                <button @click="editItem(item.id)" class="bg-blue-500 text-white px-5 py-2 rounded-lg hover:bg-blue-600 transition">Éditer</button>
                <button @click="deletePrix(item.id)" class="bg-red-500 text-white px-5 py-2 rounded-lg hover:bg-red-600 transition">Supprimer</button>
              </div>
            </li>
          </ul>
        </div>

        <!-- Détails des Items -->
        <div v-if="selectedItems.length" class="bg-white shadow-lg rounded-lg p-6 mt-8 max-h-[450px] overflow-y-auto"> <!-- Plus d'espace dans les détails -->
          <h2 class="text-3xl font-bold mb-4 overflow-y-auto">Détails des Items</h2>
          <ul class="space-y-6">
            <li v-for="item in selectedItems" :key="item.id_prix" class="flex items-center space-x-6 border-b border-gray-200 pb-4">
              <img :src="item.img" alt="Image du Prix" class="w-20 h-20 object-cover rounded-md" /> <!-- Image plus grande -->
              <div>
                <p class="text-lg font-semibold max-w-[200px] flex w-full">{{ item.nom }}</p>
              </div>
              <div class="space-x-3">
                <button @click="editItemDetails(item)" class="bg-yellow-500 text-white px-5 py-2 rounded-lg hover:bg-yellow-600 transition">Éditer</button>
                <button @click="deleteItem(item.id)" class="bg-red-500 text-white px-5 py-2 rounded-lg hover:bg-red-600 transition">Supprimer</button>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!-- Section des Formulaires -->
      <div class="max-w-2xl mx-auto"> <!-- Largeur plus grande pour le formulaire également -->
        
        <!-- Formulaire pour créer un prix -->
        <div class="bg-white shadow-lg rounded-lg p-8 mt-12">
          <h2 class="text-3xl font-bold mb-6">Ajouter un Prix</h2>
          <form @submit.prevent="createPrix">
            <div class="mb-6">
              <label for="priceName" class="block text-gray-700 font-medium mb-2">Nom du Prix</label>
              <input
                v-model="newPrixName"
                id="priceName"
                type="text"
                class="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Entrez le nom du prix"
                required
              />
            </div>
            <button
              type="submit"
              class="bg-blue-500 text-white px-5 py-3 rounded-lg hover:bg-blue-600 transition"
            >
              Créer
            </button>
          </form>
        </div>

        <!-- Formulaire pour créer un item -->
        <div v-if="!editingItem && selectedPrixId" class="bg-white shadow-lg rounded-lg p-8 mt-12">
          <h2 class="text-3xl font-bold mb-6">Ajouter un Item</h2>
          <form @submit.prevent="createItem">
            <div class="mb-6">
              <label for="itemName" class="block text-gray-700 font-medium mb-2">Nom de l'Item</label>
              <input
                v-model="newItemName"
                id="itemName"
                type="text"
                class="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Entrez le nom de l'item"
                required
              />
            </div>
            <div class="mb-6">
              <label for="itemImg" class="block text-gray-700 font-medium mb-2">URL de l'Image</label>
              <input
                v-model="newItemImg"
                id="itemImg"
                type="text"
                class="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Entrez l'URL de l'image"
                required
              />
            </div>
            <button
              type="submit"
              class="bg-blue-500 text-white px-5 py-3 rounded-lg hover:bg-blue-600 transition"
            >
              Créer Item
            </button>
          </form>
        </div>

        <!-- Formulaire pour éditer un item -->
        <div v-if="editingItem" class="bg-white shadow-lg rounded-lg p-8 mt-12">
          <h2 class="text-3xl font-bold mb-6">Éditer l'Item</h2>
          <form @submit.prevent="updateItem">
            <div class="mb-6">
              <label for="editItemName" class="block text-gray-700 font-medium mb-2">Nom de l'Item</label>
              <input
                v-model="editingItem.nom"
                id="editItemName"
                type="text"
                class="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Entrez le nom de l'item"
                required
              />
            </div>
            <div class="mb-6">
              <label for="editItemImg" class="block text-gray-700 font-medium mb-2">URL de l'Image</label>
              <input
                v-model="editingItem.img"
                id="editItemImg"
                type="text"
                class="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Entrez l'URL de l'image"
                required
              />
            </div>
            <button
              type="submit"
              class="bg-blue-500 text-white px-5 py-3 rounded-lg hover:bg-blue-600 transition"
            >
              Mettre à jour
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';

export default {
  data() {
    return {
      prixList: [],
      selectedItems: [],
      newPrixName: '',
      newItemName: '',
      newItemImg: '',
      selectedPrixId: null,
      editingItem: null
    };
  },
  async created() {
    try {
      const response = await axios.get('https://api.meritemaritime-fnmm.com/prix/list');
      this.prixList = response.data;
    } catch (error) {
      console.error('Erreur lors de la récupération des données:', error);
    }
  },
  methods: {
    async createPrix() {
      if (!this.newPrixName.trim()) return;

      try {
        await axios.post('https://api.meritemaritime-fnmm.com/prix/create', { name: this.newPrixName });
        this.newPrixName = '';
        const response = await axios.get('https://api.meritemaritime-fnmm.com/prix/list');
        this.prixList = response.data;
      } catch (error) {
        console.error('Erreur lors de la création du prix:', error);
      }
    },
    async createItem() {
      if (!this.newItemName.trim() || !this.newItemImg.trim() || !this.selectedPrixId) return;

      try {
        await axios.post('https://api.meritemaritime-fnmm.com/items/create', {
          name: this.newItemName,
          img: this.newItemImg,
          id_prix: this.selectedPrixId
        });
        this.newItemName = '';
        this.newItemImg = '';
        const response = await axios.get(`https://api.meritemaritime-fnmm.com/prix/edit/list/${this.selectedPrixId}`);
        this.selectedItems = response.data.items;
      } catch (error) {
        console.error('Erreur lors de la création de l\'item:', error);
      }
    },
    async deletePrix(id) {
      try {
        await axios.delete(`https://api.meritemaritime-fnmm.com/prix/delete`, { data: { id } });
        this.prixList = this.prixList.filter(item => item.id !== id);
        if (this.selectedPrixId === id) {
          this.selectedItems = [];
          this.selectedPrixId = null;
        }
      } catch (error) {
        console.error('Erreur lors de la suppression du prix:', error);
      }
    },
    async deleteItem(id) {
      try {
        await axios.delete(`https://api.meritemaritime-fnmm.com/prix/item/delete`, { data: { id } });
        this.selectedItems = this.selectedItems.filter(item => item.id !== id);
      } catch (error) {
        console.error('Erreur lors de la suppression de l\'item:', error);
      }
    },
    async editItem(id) {
      try {
        const response = await axios.get(`https://api.meritemaritime-fnmm.com/prix/edit/list/${id}`);
        this.selectedItems = response.data.items;
        this.selectedPrixId = id;
        this.editingItem = null; // Cache le formulaire de création d'item
      } catch (error) {
        console.error('Erreur lors de la récupération des détails:', error);
      }
    },
    editItemDetails(item) {
      this.editingItem = { ...item };
    },
    async updateItem() {
      try {
        await axios.put(`https://api.meritemaritime-fnmm.com/items/update/${this.editingItem.id}`, {
          name: this.editingItem.nom,
          img: this.editingItem.img,
          id_prix: this.editingItem.id_prix
        });
        this.editingItem = null;
        const response = await axios.get(`https://api.meritemaritime-fnmm.com/prix/edit/list/${this.selectedPrixId}`);
        this.selectedItems = response.data.items;
      } catch (error) {
        console.error('Erreur lors de la mise à jour de l\'item:', error);
      }
    }
  }
};
</script>
