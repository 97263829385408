<script>
import axios from 'axios';

export default {
  name: "SliderImg",
  data() {
    return {
      lastArticle: {
        id_aritcle_familly: null,
        photo: ''
      },
      lastPrix: {
        id: null,
        photo: ''
      }
    };
  },
  created() {
    this.fetchLastArticle();
    this.fetchLastPrix();
  },
  methods: {
    async fetchLastArticle() {
      try {
        const response = await axios.post('https://api.meritemaritime-fnmm.com/lastarticle');
        this.lastArticle.id_aritcle_familly = response.data.id_aritcle_familly;
        this.lastArticle.photo = response.data.photo;
      } catch (error) {
        console.error('Error fetching last article:', error);
      }
    },
    async fetchLastPrix() {
      try {
        const response = await axios.post('https://api.meritemaritime-fnmm.com/lastprix');
        this.lastPrix.id = response.data.id;
        this.lastPrix.img = response.data.img;
      } catch (error) {
        console.error('Error fetching last prix:', error);
      }
    }
  }
};
</script>

<template>
  <div class="flex w-full items-center px-6 justify-center mt-56 titre">
    <div class="w-full flex max-w-screen-lg">
      <h1 class="text-4xl">
        Bienvenue sur le site officiel de la Fédération Nationale du Mérite Maritime
        <span class="title font-black"> et de la Médaille d'Honneur des Marins</span>
      </h1>
    </div>
  </div>
  <section class="flex mb-24">
    <div class="slider-container slider-1">
      <div class="slider max-w-screen-lg">
        <a :href="`/nouvelles-de-la-federation/article/${lastArticle.id_aritcle_familly}`">
          <img :src="lastArticle.photo" class="rounded" v-if="lastArticle.photo">
        </a>
        <a href="/centre-de-documentation-maritime">
          <img src="@/assets/img/banners/img2.png" class="rounded">
        </a>
        <a href="/histoire-maritime">
          <img src="@/assets/img/banners/img3.png" class="rounded">
        </a>
        <a :href="`/prix-litteraires/${lastPrix.id}`">
          <img :src="lastPrix.img" class="rounded" v-if="lastPrix.img">
        </a>
        <a href="/nouvelles-de-la-federation">
          <img src="@/assets/img/index/img1-banner.png" class="rounded">
        </a>
      </div>
    </div>
  </section>
</template>

<style scoped>
.title {
  font-family: 'Lato', sans-serif;
}

@media (max-width: 800px) {
  .title {
    display: none;
  }
}

@media (max-width: 1024px) {
  .titre {
    margin-top: 100px;
  }
}

.slider-1 {
  width: 100%;
  margin: 20px auto;
  overflow: hidden;
}

.slider-1 .slider {
  animation: slider-1 12s infinite ease-in-out;
  display: flex;
}

.slider-1 a {
  flex-shrink: 0;
  width: 100%;
}

.slider-1 a img {
  display: flex;
  width: 100%;
  height: 382px;
  object-fit: cover;
}

@keyframes slider-1 {
  0%,
  20% {
    transform: translateX(0);
  }
  25%,
  45% {
    transform: translateX(-100%);
  }
  50%,
  70% {
    transform: translateX(-200%);
  }
  75%,
  95% {
    transform: translateX(-300%);
  }
  100% {
    transform: translateX(-400%);
  }
}
</style>
